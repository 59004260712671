import * as React from "react"
import { Suspense } from "react"
//import component list
import CoursesLanding from '../components/courses-pages/courses-landing'
import LoadingSpinner from '../components/_loading-spinner'

const CoursesBuckets = React.lazy(() => import('../components/courses-pages/courses-buckets'))
const CoursesTables = React.lazy(() => import('../components/courses-pages/courses-table'))
const CoursesMiniDuplex = React.lazy(() => import('../components/courses-pages/courses-mini-duplex'))
const CoursesCenteredText = React.lazy(() => import('../components/courses-pages/courses-centered-text'))
const CourseMiniCta = React.lazy(() => import('../components/courses-pages/courses-mini-cta'))
const CoursesFooter = React.lazy(() => import('../components/courses-pages/courses-footer'))

const ContentArea = ({layoutPieces}) => {
    if (layoutPieces) {
      return layoutPieces.map((layoutPiece, index) => {
        return ([
          layoutPiece.internal.type === 'ContentfulCoursesPageBanner' && <CoursesLanding layoutPiece={layoutPiece} key={index}/>,
          layoutPiece.internal.type === 'ContentfulBucketComponent' && <Suspense key={index} fallback={<LoadingSpinner />}><CoursesBuckets layoutPiece={layoutPiece} /></Suspense>,
          layoutPiece.internal.type === 'ContentfulCoursesTableLayout' && <Suspense key={index} fallback={<LoadingSpinner />}><CoursesTables layoutPiece={layoutPiece} /></Suspense>,
          layoutPiece.internal.type === 'ContentfulDuplexFeature' && <Suspense key={index} fallback={<LoadingSpinner />}><CoursesMiniDuplex layoutPiece={layoutPiece} /></Suspense>,
          layoutPiece.internal.type === 'ContentfulCenteredText' && <Suspense key={index} fallback={<LoadingSpinner />}><CoursesCenteredText layoutPiece={layoutPiece} /></Suspense>,
          layoutPiece.internal.type === 'ContentfulGlobalIntro' && <Suspense key={index} fallback={<LoadingSpinner />}><CourseMiniCta layoutPiece={layoutPiece} /></Suspense>,
          layoutPiece.internal.type === 'ContentfulCoursesPageFooter' && <Suspense key={index} fallback={<LoadingSpinner />}><CoursesFooter layoutPiece={layoutPiece} /></Suspense>,
        ])
      })
    }
}

export default ContentArea
