import React from 'react'
import { Link, graphql } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import CoursesCorpLogo from '../../assets/entrata-logo-white.svg'
import '../../styles/courses-pages/courses-landing.css'

export default function CoursesLanding({ layoutPiece }) {
  const coursesLandingImage = getImage(layoutPiece?.backgroundImage?.image)
  const coursesLogo1 = getImage(layoutPiece?.logoOrAward1?.image)
  const coursesLogo2 = getImage(layoutPiece?.logoOrAward2?.image)
  return (
    <section className='courses-page courses-landing-section courses-landing-background'>
      <div className='courses-content-cap landing-content-cap'>
        <div className="courses-landing-grid">
          <div className="courses-landing-text">
            <Link to='/'>
              <CoursesCorpLogo className="courses-corp-logo" />
            </Link>
            <h1>{layoutPiece?.headlineText}</h1>
            <p>
              <ReactMarkdown>{layoutPiece?.bodyText?.bodyText}</ReactMarkdown>
            </p>
            <div className="courses-cta-row">
              {layoutPiece?.button?.fullUrl ?
                <a href={layoutPiece?.button?.fullUrl} className="courses-button">
                  {layoutPiece?.button?.buttonText}
                </a>
              : layoutPiece?.button?.slug ?
                <Link to={layoutPiece?.button?.slug} className="courses-button">
                  {layoutPiece?.button?.buttonText}
                </Link>
              : ''
              }

              <div className="courses-logo-brag">
                <div className="courses-landing-logo-image">
                  <GatsbyImage image={coursesLogo1} alt={coursesLogo1?.description} className="courses-logo-actual" />
                </div>
                <div className="courses-landing-logo-image">
                  <GatsbyImage image={coursesLogo2} alt={coursesLogo2?.description} className="courses-logo-actual" />
                </div>
              </div>
            </div>
          </div>
          <div className="courses-landing-image-holder"></div>
        </div>
        <GatsbyImage image={coursesLandingImage} alt={coursesLandingImage?.description} className="courses-landing-background-image" />
        <div className="courses-landing-gradient-bkg"></div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment CoursesPageBanner on ContentfulCoursesPageBanner {
    id
    headlineText
    bodyText {
      bodyText
    }
    logoOrAward1 {
      image {
        gatsbyImageData(width: 800, placeholder: BLURRED)
        url
        title
        description
      }
    }
    logoOrAward2 {
      image {
        gatsbyImageData(width: 800, placeholder: BLURRED)
        url
        title
        description
      }
    }
    backgroundImage {
      image {
        gatsbyImageData(width: 800, placeholder: BLURRED)
        url
        title
        description
      }
    }
    button {
      buttonText
      fullUrl
      slug
    }
  }
`
