import * as React from "react"
import { graphql } from 'gatsby'
//import component pieces
import CoursesContentArea from '../templates/courses-content-area'
import ogMetaImage from '../../static/entratalogo_1200x630.png'

export default function CourcesPage({ data, pageContext }) {
  const layoutPieces = data.contentfulCoursesPageTemplate.pageComponents
  return (
    <CoursesContentArea layoutPieces={layoutPieces}/>
  )
}

export const Head = ({data}) => (
  <>
    <meta property="og:title" content={data?.contentfulCoursesPageTemplate?.metadata?.ogMetaTitle ? data?.contentfulCoursesPageTemplate?.metadata?.ogMetaTitle : "Entrata | Courses"} />
    <meta property="og:description" content={data?.contentfulCoursesPageTemplate?.metadata?.ogMetaDescription ? data?.contentfulCoursesPageTemplate?.metadata?.ogMetaDescription : "Learn all the necessary skills to empower your team with Entrata official courses"} />
    <meta property="og:url" content="https://www.entrata.com/blog" />
    <meta name="description" content={data?.contentfulCoursesPageTemplate?.metadata?.metaDescription ? data?.contentfulCoursesPageTemplate?.metadata?.metaDescription : "Learn all the necessary skills to empower your team with Entrata official courses"} />
    <title>{data?.contentfulCoursesPageTemplate?.metadata?.ogMetaTitle ? data?.contentfulCoursesPageTemplate?.metadata?.metaTitle : "Entrata | Courses"}</title>
    <meta property="og:image" content={data?.contentfulCoursesPageTemplate?.metadata?.ogMetaImage?.url ? data?.contentfulCoursesPageTemplate?.metadata?.ogMetaImage?.url : ogMetaImage} />
  </>
)

export const query = graphql`
  query CoursesComponentPieces($slug: String!) {
    contentfulCoursesPageTemplate(slug: { eq: $slug }) {
      slug
      metadata {
        metaDescription
        metaTitle
        ogMetaDescription
        ogMetaImage {
          url
        }
        ogMetaTitle
        ogMetaType
      }
      pageComponents {
        ... on Node {
          internal {
            type
          }
          ... on ContentfulCoursesPageBanner {
            ...CoursesPageBanner
          }
          ... on ContentfulBucketComponent {
            ...CoursesBucketComponent
          }
          ... on ContentfulCoursesTableLayout {
            ...CoursesTableLayout
          }
          ... on ContentfulDuplexFeature {
            ...CoursesMiniDuplex
          }
          ... on ContentfulCenteredText {
            ...CoursesCenteredText
          }
          ... on ContentfulGlobalIntro {
            ...CoursesMiniCTA
          }
          ... on ContentfulCoursesPageFooter {
            ...CoursesPageFooter
          }
        }
      }
    }
  }
`
